import Head from 'next/head';
import { useRouter } from 'next/router';

interface Props {
    title?: string;
    description?: string;
    keywords?: string;
    image?: string;
    links?: { rel: string; href: string }[];
}

const HeadMeta = ({
    title = '',
    description = '',
    keywords = '',
    image = '',
    links = [],
}: Props) => {
    const router = useRouter();

    const appUrl = process.env.NEXT_PUBLIC_APP_URL;

    const seoImageurl = (url: string) => {
        if (!url) return `${appUrl}/fb.png`;
        return ~image.indexOf('https://') ? image : `${appUrl}/${image}`;
    };

    return (
        <Head>
            <meta charSet="UTF-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />

            <title>{title ? title + ' - SpotOn' : 'SpotOn'}</title>
            <meta name="title" content={title || ''} />
            <meta name="description" content={description || ''} />
            <meta name="keywords" content={keywords || ''} />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=5.0, shrink-to-fit=yes"
            />

            <meta property="og:url" content={appUrl + '/' + router.asPath} />
            <meta
                property="og:title"
                content={title || 'Elektrikertjenester gjort enkelt'}
            />
            <meta
                property="og:description"
                content={
                    description ||
                    'Bestill på nett, få fastpris på minutter og jobben gjort når det passer deg.'
                }
            />
            <meta property="og:image" content={seoImageurl(image)} />

            <meta property="twitter:title" content={title || 'SpotOn'} />
            <meta property="twitter:description" content={description || ''} />

            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="/icons/apple-touch-icon.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/icons/favicon-32x32.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/icons/favicon-16x16.png"
            />
            <link rel="manifest" href="/site.webmanifest" />
            <link
                rel="mask-icon"
                href="/icons/safari-pinned-tab.svg"
                color="#ff6400"
            />
            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="theme-color" content="#fff"></meta>

            {links.map((l) => (
                <link key={l.href} {...l} />
            ))}
        </Head>
    );
};

export default HeadMeta;
