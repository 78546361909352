import moment from '/lib/utils/moment';
import { useEffect } from 'react';
import Router from 'next/router';
import Link from 'next/link';
import {
    pipe,
    toUpper,
    split,
    map,
    head,
    join,
    compose,
    not,
    isEmpty,
    isNil,
    filter,
    curry,
} from 'ramda';

export { default as userIs } from './utils/userIs';
export { default as areValidChildren } from './utils/areValidChildren';
export { default as isTouchDevice } from './utils/isTouchDevice';
export { default as insertAtIndex } from './utils/insertAtIndex';
export { default as matchText } from './utils/matchText';
export { default as formatDateRange } from './utils/formatDateRange';
export { default as toInt } from './utils/toInt';
export { default as camelcaseKeys } from './utils/camelcaseKeys';
import isObject from './utils/isObject';
export { isObject };
export { default as lineItemToJob } from './utils/lineItemToJob';
export { default as parseQueryParams } from './utils/parseQueryParams';
export { default as scrollToId } from './utils/scrollToId';
export { default as unique } from './utils/unique';
export { default as formatBigNumber } from './utils/formatBigNumber';
export { default as stripHtmlTags } from './utils/stripHtmlTags';

export const cookie = {
    set: (cname, cvalue, exdays = 1) => {
        if (typeof window === 'undefined') return false;
        var d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        var expires = 'expires=' + d.toUTCString();
        document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    },
    get: (cname) => {
        if (typeof window === 'undefined') return false;
        var name = cname + '=';
        return (
            document.cookie
                .split(';')
                .map((s) => s.trim())
                .filter((s) => ~s.indexOf(name))
                .map((s) => s.replace(name, ''))[0] || ''
        );
    },
    getFromString: (cname, string) => {
        if (!string) return '';
        var name = cname + '=';
        return (
            string
                .split(';')
                .map((s) => s.trim())
                .filter((s) => ~s.indexOf(name))
                .map((s) => s.replace(name, ''))[0] || ''
        );
    },
};

export const scrollWindowTo = (
    destination,
    duration = 200,
    easing = 'easeInOutCubic',
    callback
) => {
    const easings = {
        linear: (t) => t,
        easeInOutCubic: (t) =>
            t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
    };

    if (!destination && destination !== 0) return;

    const start = window.pageYOffset;
    const startTime =
        'now' in window.performance ? performance.now() : new Date().getTime();

    const documentHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
    );
    const windowHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.getElementsByTagName('body')[0].clientHeight;
    const destinationOffset =
        typeof destination === 'number' ? destination : destination.offsetTop;
    const destinationOffsetToScroll = Math.round(
        documentHeight - destinationOffset < windowHeight
            ? documentHeight - windowHeight
            : destinationOffset
    );

    if ('requestAnimationFrame' in window === false) {
        window.scroll(0, destinationOffsetToScroll);
        if (callback) {
            callback();
        }
        return;
    }

    function scroll() {
        const now =
            'now' in window.performance
                ? performance.now()
                : new Date().getTime();
        const time = Math.min(1, (now - startTime) / duration);
        const timeFunction = easings[easing](time);
        window.scroll(
            0,
            Math.ceil(
                timeFunction * (destinationOffsetToScroll - start) + start
            )
        );

        if (
            Math.ceil(window.pageYOffset) ===
            Math.ceil(destinationOffsetToScroll)
        ) {
            if (callback) {
                callback();
            }
            return;
        }

        requestAnimationFrame(scroll);
    }

    scroll();
};

export const getElementOffsetY = (element) => {
    if (typeof window === 'undefined' || !element) return 0;

    const bodyRect = document.body.getBoundingClientRect(),
        elemRect = element.getBoundingClientRect();

    return elemRect.top - bodyRect.top;
};

export const isServer = typeof window === 'undefined';

export const hex2rgb = (hex) => {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',');
    }
    throw new Error('Invalid HEX value provided for BarLoader prop "color" ');
};

export const capitalize = (str) =>
    String(str)
        .split(' ')
        .map((w) => w[0].toUpperCase() + w.slice(1))
        .join(' ');

export const capitalizeFirst = (str) => {
    if (!str) return '';
    return String(str)[0].toUpperCase() + String(str).slice(1);
};

export const bytesToSize = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (!bytes) return 'n/a';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) return `${bytes} ${sizes[i]})`;
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

export const truncate = (fullStr, strLen) => {
    if (typeof fullStr !== 'string') return '';

    if (fullStr.length <= strLen) return fullStr;

    const separator = '...';
    const sepLen = separator.length,
        charsToShow = strLen - sepLen,
        frontChars = Math.ceil(charsToShow / 2),
        backChars = Math.floor(charsToShow / 2);

    return (
        fullStr.substr(0, frontChars) +
        separator +
        fullStr.substr(fullStr.length - backChars)
    );
};

export const formatDateTime = (dateString, includeDay = false) => {
    if (!dateString) return '';

    const date = moment(dateString);
    const formatted = `${date.format('DD. MMMM')} • kl. ${date.format(
        'HH:mm'
    )}`;
    return includeDay ? `${date.format('dddd')} ${formatted}` : formatted;
};

export const formatDateTimeWithYear = (dateString) => {
    if (!dateString) return '';

    const date = moment(dateString);
    return `${date.format('DD.MM.YY')} • kl. ${date.format('HH:mm')}`;
};

export const formatDate = (dateString, format = 'DD. MMMM YYYY') => {
    if (!dateString) return '';

    const date = moment(dateString);

    return date.isValid() ? `${date.format(format)}` : '';
};

export const formatThousands = (val) => {
    if (isNaN(Math.ceil(val))) return '';
    return (Math.round((val / 1000) * 10) / 10 + "'").replace('.', ',');
};

export const formatPrice = (val) => {
    if (isNaN(Math.ceil(val))) return '';
    return 'kr ' + String(Math.ceil(val)).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const formatNumber = (val) => {
    if (!val) return '';
    if (isNaN(Math.ceil(val))) return '';
    return String(Math.ceil(val)).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const formatDiscountPrice = (val) => {
    const price = formatPrice(val).replace('-', '');
    return price ? `- ${price}` : '';
};

export const formatDiscountGrossPrice = (val) => {
    const price = formatDiscountPrice(val);
    return price ? `${price} Inkl. mva` : '';
};

export const formatTaxPrice = (val) => 'Inkl. mva';

export const snakeToCamel = (str) => {
    return str.replace(/([-_][a-z])/g, (group) =>
        group.toUpperCase().replace('-', '').replace('_', '')
    );
};

export const camelToSnake = (str) => {
    return str
        .replace(/(.)([A-Z][a-z]+)/, '$1_$2')
        .replace(/([a-z0-9])([A-Z])/, '$1_$2')
        .toLowerCase();
};

export const camelToKebab = (str) => {
    return str
        .replace(/(.)([A-Z][a-z]+)/, '$1-$2')
        .replace(/([a-z0-9])([A-Z])/, '$1-$2')
        .toLowerCase();
};

export const kebabCase = (str) => {
    return str
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/[\s_]+/g, '-')
        .toLowerCase();
};

export const useOnClickOutside = (ref, callback) => {
    const handleClick = (e) => {
        if (ref.current && !ref.current.contains(e.target)) callback(e);
    };

    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [ref]);
};

export const stringContainsOneOf = (string, array) => {
    return array.reduce((acc, item) => {
        return ~string.indexOf(item) ? true : acc;
    }, false);
};

export const stringStartsWithOneOf = (string, array) => {
    return array.reduce((acc, item) => {
        return String(string).indexOf(item) === 0 ? true : acc;
    }, false);
};

export const pathNameIs = (pathName) => {
    return {
        backend: stringStartsWithOneOf(pathName || '', [
            '/dashboard',
            '/definition',
            '/project',
            '/inspection',
            '/claim',
            '/offer',
            '/analytics',
            '/settings',
            '/calendar',
            '/electrician',
            '/standard-availability',
            '/contractor',
            '/product',
            '/seller-dashboard',
            '/concerns',
            '/customer',
            '/draft-version',
            '/resource',
            '/sales-partner',
        ]),
        checkout: stringStartsWithOneOf(pathName || '', ['/order']),
        auth: stringStartsWithOneOf(pathName || '', ['/auth']),
        home: (pathName || '') === '/',
        onboarding: stringStartsWithOneOf(pathName || '', ['/onboarding']),
        configurator: stringStartsWithOneOf(pathName || '', ['/order/job']),
        videoInspectionTimer:
            stringStartsWithOneOf(pathName || '', ['/project']) &&
            stringContainsOneOf(pathName || '', [
                '/inspection/video-meeting-redirect',
            ]),
    };
};

export const momentFromWeekDay = (day) =>
    moment().locale('en').isoWeekday(capitalize(day)).locale('nb');

export const goTo = (url, asNewTab = null, afterPush = () => null) => {
    if (typeof window === 'undefined') return;
    if (asNewTab) {
        return window
            .open(process.env.NEXT_PUBLIC_APP_URL + url, '_blank')
            .focus();
    }
    if (window.event && (window.event.metaKey || window.event.ctrlKey)) {
        return window.open(process.env.NEXT_PUBLIC_APP_URL + url, '_blank');
    }
    Router.push(url).then(() => {
        window.scrollTo(0, 0);
        afterPush();
    });
    return null;
};

export const kebabToCamel = (str) =>
    str.replace(/-([a-z])/g, (g) => g[1].toUpperCase());

export const sortByPath = (array = [], path = '', asNumber = false) => {
    const pathParts = path.split('.');
    return [...array].sort((a, b) => {
        let A = a[pathParts[0]];
        if (pathParts.length > 1) A = a[pathParts[0]][pathParts[1]];
        if (pathParts.length > 2)
            A = a[pathParts[0]][pathParts[1]][pathParts[2]];
        A = asNumber ? Number(A) : A.toUpperCase();

        let B = b[pathParts[0]];
        if (pathParts.length > 1) B = b[pathParts[0]][pathParts[1]];
        if (pathParts.length > 2)
            B = b[pathParts[0]][pathParts[1]][pathParts[2]];
        B = asNumber ? Number(B) : B.toUpperCase();

        let comparison;
        if (A > B) {
            comparison = 1;
        } else if (A < B) {
            comparison = -1;
        }
        return comparison;
    });
};

export const roundTo = (number, precision = 0) => {
    const power = Math.pow(10, precision);
    return Math.ceil((number || 0) * power) / power;
};

export const relevantGeneralAvailability = (electrician) => {
    if (!electrician) return null;
    if (electrician.availability) return electrician.availability;
    const gen = electrician.generalAvailability;
    if (gen && (gen.future || gen.current))
        return gen.future ? gen.future.availability : gen.current.availability;
    return null;
};

export const arraysEqual = (a, b) => {
    return (
        Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index])
    );
};

export const debounce = (func, wait) => {
    let timeout;
    return function (...args) {
        const context = this;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            timeout = null;
            func.apply(context, args);
        }, wait);
    };
};

export const trim2 = (val) => parseInt(val * 100) / 100;

export const trim1 = (val) => parseInt(val * 10) / 10;

export const percentOf = (x, y) => {
    const p = Math.ceil((x / (y || 1)) * 100);
    return isNaN(p) ? 0 : p;
};

export const percentToNum = (per, max) => Math.ceil((max / 100) * per);

export const isInIframe = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
};

export const changeArrayItemPosition = (array, currentIndex, newIndex) => {
    let reorderedarray = [...array];
    const x = reorderedarray[currentIndex];
    reorderedarray[currentIndex] = reorderedarray[newIndex];
    reorderedarray[newIndex] = x;
    return reorderedarray;
};

export const objectToQuery = (obj) =>
    Object.keys(obj || {})
        .filter((key) => obj[key])
        .map((key) => {
            if (Array.isArray(obj[key])) {
                return obj[key]
                    .filter((val) => val)
                    .map((val) => `${camelToSnake(key)}[]=${val}`)
                    .join('&');
            }
            return `${camelToSnake(key)}=${obj[key]}`;
        })
        .filter((val) => val)
        .join('&');

export const isEmail = (email) =>
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    );

export const isPhone = (val) => {
    if (String(val).indexOf('+48') === 0)
        return /^(0048|\+48|48)?\d{9}$/.test(val);
    return /^(0047|\+47|47)?\d{8}$/.test(val);
};

export const stripEmptyProps = (obj) => filter(compose(not, isEmpty), obj);

export const stripNilProps = (obj) => filter(compose(not, isNil), obj);

export const stripUndefinedProps = (obj) =>
    filter(
        compose(
            not,
            curry((item) => item === undefined)
        ),
        obj
    );

export const stripProps = (props, obj) => {
    return array(props).reduce((obj, key) => {
        const { [key]: unused, ...rest } = obj;
        return rest;
    }, obj);
};

export const isSupported = (getStorage) => {
    try {
        const key = '__test__';
        getStorage().setItem(key, key);
        getStorage().removeItem(key);
        return true;
    } catch (e) {
        return false;
    }
};

export const box = (val) =>
    (Array.isArray(val) ? val : [val]).filter((val) => val !== undefined);

export const array = (val) => (!val ? [] : Array.isArray(val) ? val : [val]);

export const bool = (val) => (val === true ? val : false);

export const isRetina = () => {
    if (typeof window === 'undefined') return false;
    const mediaQuery =
        '(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx)';
    if (window.devicePixelRatio > 1) return true;
    if (window.matchMedia && window.matchMedia(mediaQuery).matches) return true;
    return false;
};

export const imgixUrl = (url, { width }) => {
    if (!url) return '';

    if (url.indexOf('?')) url = url.split('?')[0];

    return `${url}?w=${width || 1120}&fm=jpg&auto=format&dpr=${
        isRetina() ? 2 : 1
    }`;
};

export const decorateText = (text) => {
    if (!text) return '';

    const prepare = (item, regex) => {
        if (typeof item !== 'string') return { matches: false, splits: false };

        const matches = item.match(regex);

        if (!matches) return { matches: false, splits: false };

        const splits = item.split(regex);

        return { matches, splits };
    };

    const parseLinks = (acc, item, i) => {
        const { matches, splits } = prepare(item, /\[[\wæÆØøåÅ\s-#\/]+\]/g);

        if (!matches || !splits) return [...acc, item];

        return [
            ...acc,
            ...splits.reduce((acc, item, j) => {
                const linekShortcode = matches[j];

                if (!linekShortcode) return [...acc, item];

                let href = '';
                let text = '';
                let path = '';
                let target = '_self';

                if (~linekShortcode.indexOf(' /')) {
                    text = linekShortcode.split(' /')[0].replace('[', '');
                    path = '/' + linekShortcode.split(' /')[1].replace(']', '');
                    href = path;
                    if (~path.indexOf('_blank')) {
                        path = path.replace('_blank', '').trim();
                        href = path; // opt out of using href & as - next allows to use only href and does resolving internally
                        target = '_blank';
                    }
                    if (~path.indexOf('/bestilling/jobb')) {
                        //href = '/order/job/[slug]'
                        href = path; // opt out of using href & as - next allows to use only href and does resolving internally
                    }
                }

                return [
                    ...acc,
                    item,
                    <Link
                        prefetch={false}
                        key={'l-' + i + '-' + j}
                        href={href}
                        target={target}
                    >
                        {text}
                    </Link>,
                ];
            }, []),
        ];
    };

    const parseLinebreaks = (acc, item, i) => {
        const { matches, splits } = prepare(item, /(?:<br\s?\/>)|(?:\n)/g);

        if (!matches || !splits) return [...acc, item];

        const lastSplit = splits.pop();

        const addBrs = (acc, item, j) => [
            ...acc,
            item,
            <br key={'b-' + i + '-' + j} />,
        ];

        return [...acc, ...splits.reduce(addBrs, []), lastSplit];
    };

    const parseEmails = (acc, item, i) => {
        const { matches, splits } = prepare(
            item,
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi
        );

        if (!matches || !splits) return [...acc, item];

        return [
            ...acc,
            ...splits.reduce((acc, item, j) => {
                if (!matches[j]) return [...acc, item];
                return [
                    ...acc,
                    item,
                    <a key={'e-' + i + '-' + j} href={`mailto:${matches[j]}`}>
                        {matches[j]}
                    </a>,
                ];
            }, []),
        ];
    };

    const parsePhones = (acc, item, i) => {
        const { matches, splits } = prepare(item, /\+47[0-9]{8}/g);

        if (!matches || !splits) return [...acc, item];

        return [
            ...acc,
            ...splits.reduce((acc, item, j) => {
                if (!matches[j]) return [...acc, item];
                return [
                    ...acc,
                    item,
                    <a key={'l-' + i + '-' + j} href={`tel:${matches[j]}`}>
                        {matches[j]}
                    </a>,
                ];
            }, []),
        ];
    };

    const parseUrls = (acc, item, i) => {
        const { matches, splits } = prepare(
            item,
            /http[s]?:\/\/(?:[a-zA-Z]|[0-9]|[$-_@.&+]|[!*\(\),]|(?:%[0-9a-fA-F][0-9a-fA-F]))+/gi
        );

        if (!matches || !splits) return [...acc, item];

        return [
            ...acc,
            ...splits.reduce((acc, item, j) => {
                if (!matches[j]) return [...acc, item];
                return [
                    ...acc,
                    item,
                    <a
                        key={'u-' + i + '-' + j}
                        target="_blank"
                        rel="noreferrer"
                        href={matches[j]}
                    >
                        {matches[j]}
                    </a>,
                ];
            }, []),
        ];
    };

    return [String(text).trim()]
        .reduce(parseLinks, [])
        .reduce(parseLinebreaks, [])
        .reduce(parseEmails, [])
        .reduce(parsePhones, [])
        .reduce(parseUrls, []);
};

export const latinise = (str) => {
    const translate = {
        ä: 'a',
        å: 'a',
        æ: 'ae',
        ö: 'o',
        ø: 'o',
        ü: 'u',
        Ä: 'A',
        Å: 'A',
        Æ: 'AE',
        Ö: 'O',
        Ø: 'O',
        Ü: 'U',
    };
    return str.replace(/[öøäåæüÖØÄÅÆÜ]/g, (match) => translate[match] || '');
};

export const areSameObjects = (a, b) =>
    isObject(a) && isObject(b) && JSON.stringify(a) === JSON.stringify(b);

export const areSameArrays = (a, b) =>
    Array.isArray(a) &&
    Array.isArray(b) &&
    JSON.stringify(a) === JSON.stringify(b);

export const inputFocusBlur = (id, scroll = true) => {
    const input = document.getElementById(id);
    if (input) {
        input.focus({ preventScroll: true });
        input.blur();
        if (scroll)
            input.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
};

export const formatPhoneNumber = (phoneNumber) => {
    const prefix = phoneNumber.indexOf('+') === 0 ? '+' : '';
    return `${prefix}${phoneNumber
        .replace('+', '')
        .match(/.{1,2}/g)
        .join(' ')}`;
};

export const updateObjectPath = (object, path, value) => {
    let keys = path.split('.').filter((k) => k);
    let clone = JSON.parse(JSON.stringify(object));
    let temp = clone;

    for (let index = 0; index < keys.length; index++) {
        const key = keys[index];

        if (index === keys.length - 1) {
            clone[key] = value;
        } else if (!isObject(clone[key])) {
            clone[key] = {};
        }

        clone = clone[key];
    }

    return temp;
};

export const acronym = pipe(toUpper, split(' '), map(head), join(''));

export const windowHeight = () =>
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;
