import { objectToQuery } from '/lib/helpers';
import { PageType } from '/front/types/frontPageType';
import snakecaseKeys from 'snakecase-keys';
import { SpotonSimpleModuleType } from '/front/types/frontModuleType';
import { PaginatedResponse, Error } from '/types/apiResponseTypes';
import ImageType from '/front/types/frontImageType';
import { Material } from '/types/jobTemplateTypes';

import api from './api';

interface BasePage {
    uuid: string;
    type: PageType;
    id: number;
    parentId: number | null;
    draftId?: number | null;
    published: boolean;
    title: string;
    slug: string;
    path: string;
    data: {
        [key: string]: any;
    };
    createdAt: string;
    updatedAt: string;
}

export interface CmsPage extends BasePage {
    data: {
        seo: {
            title: string;
            description: string;
            keywords: string[];
            image: ImageType | null;
        };
        background: string;
        modules: SpotonSimpleModuleType[];
    };
}

export interface ProductPage extends Omit<BasePage, 'type'> {
    type: 'product';
    data: {
        content: string;
        description: string;
        eiProduct: Material | null;
        images: { url: string }[];
        specification: {
            label: string;
            value: string;
            id?: string;
        }[];
    };
}

export type PageTypeQueryParam = PageType | 'job' | 'product';

export interface QueryParams {
    uuid?: string;
    id?: number;
    slug?: string;
    path?: string;
    search?: string;
    type?: PageTypeQueryParam | PageTypeQueryParam[];
    page?: number;
    perPage?: number;
    published?: boolean;
}

export interface LinkSearchResult {
    id: number;
    parentId: number;
    path: string;
    similarity: number;
    slug: string;
    title: string;
    type: PageType | 'job';
    uuid: string;
}

export interface SomeOfCmsPage extends Partial<CmsPage> {}

export interface SomeOfProductPage extends Partial<ProductPage> {}

type PageOfType = {
    page: CmsPage;
    product: ProductPage;
};

type SomeOfPageOfType = {
    page: SomeOfCmsPage;
    product: SomeOfProductPage;
};

type ReturnPageType = keyof PageOfType;

// Builder with type discriminator (pageType) for type inference
const pageApiBuilder = <T extends ReturnPageType>(pageType: T) => ({
    index: (
        params: QueryParams
    ): Promise<PaginatedResponse<PageOfType[T]> | Error> =>
        api.get(`page?${objectToQuery(params)}`),

    getById: (id: number): Promise<PageOfType[T] | Error> =>
        api.getSecure(`page/${id}`),

    create: (data: PageOfType[T]): Promise<PageOfType[T] | Error> =>
        api.post(`page`, snakecaseKeys(data, { deep: false })),

    update: (
        id: number | string,
        data: SomeOfPageOfType[T]
    ): Promise<PageOfType[T] | Error> =>
        api.patch(`page/${id}`, snakecaseKeys(data, { deep: false })),

    delete: (id: number | string): Promise<{} | Error> =>
        api.delete(`page/${id}`),

    linkSearch: (
        params: QueryParams
    ): Promise<PaginatedResponse<LinkSearchResult> | Error> =>
        api.getSecure(`cms/link-search?${objectToQuery(params)}`),
});

export const cmsPageApi = pageApiBuilder('page');

export const productPageApi = pageApiBuilder('product');
