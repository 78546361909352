import { cmsPageApi, CmsPage, QueryParams } from '/api/page';
import { stripNilProps } from '/lib/helpers';
import snakecaseKeys from 'snakecase-keys';
import Page from '/front/types/frontPageType';
import api from '/api';
import ModuleType, {
    SimpleModuleType,
    SpotonSimpleModuleType,
} from '/front/types/frontModuleType';
import { PaginatedResponse, Error } from '/types/apiResponseTypes';

interface PageGetParams extends QueryParams {
    id?: number;
}

interface PagesWithMeta {
    data: Page[];
    meta: PaginatedResponse<Page>['meta'];
}

export const toSpotonModuleFormat = (module: SimpleModuleType) => {
    const { _key, _type, data } = module;
    return {
        key: _key,
        type: _type,
        data: snakecaseKeys(data, {
            deep: true,
            exclude: ['_key', '_type'],
        }),
    };
};

export const toSpotonPageFormat = (page: Page): CmsPage => ({
    uuid: page._id,
    type: page._type,
    id: page.id,
    parentId: page.parentId,
    published: page.published,
    title: page.title,
    slug: page.slug,
    path: page.path,
    data: {
        modules: (page.modules || []).map(toSpotonModuleFormat),
        seo: page.seo,
        background: page.background || '#ffffff',
    },
    createdAt: page.createdAt,
    updatedAt: page.updatedAt,
});

export const toFrontModuleFormat = (module: SpotonSimpleModuleType) => {
    const { key, type, data } = module;

    let moduleData = data;

    // module_template module has modules in its 'data' so we need to format those as well
    if (type === 'module_template' && data.modules) {
        moduleData.modules = moduleData.modules.map(toFrontModuleFormat);
    }

    return { _key: key, _type: type, data: moduleData };
};

export const toFrontPageFormat = (page: CmsPage): Page => ({
    _id: page.uuid || '',
    _type: page.type || 'page',
    id: page.id || 0,
    parentId: page.parentId || null,
    draftId: page.draftId || null,
    published: page.published || true,
    title: page.title || '',
    slug: page.slug || '',
    path: page.path || '',
    modules: (page?.data?.modules || []).map(toFrontModuleFormat),
    seo: page?.data?.seo || {
        title: '',
        description: '',
        keywords: [],
        image: null,
    },
    createdAt: page.createdAt || '',
    updatedAt: page.updatedAt || '',
    background: page?.data?.background || '#ffffff',
});

const formatParams = (params: PageGetParams): QueryParams =>
    stripNilProps({
        published: params.published,
        type: params.type,
        uuid: params.uuid,
        id: params.id,
        slug: params.slug,
        path: params.path,
        search: params.search ? encodeURIComponent(params.search) : null,
        page: params.page || 1,
        perPage: params.perPage || 20,
    });

const frontPageApi = {
    get: async (params: PageGetParams): Promise<Page[] | Error> => {
        const resp = await cmsPageApi.index(formatParams(params));

        if ('error' in resp) return { error: resp.error };

        return resp.data.map(toFrontPageFormat);
    },
    getWithMeta: async (
        params: PageGetParams
    ): Promise<PagesWithMeta | Error> => {
        const resp = await cmsPageApi.index(formatParams(params));

        if ('error' in resp) return { error: resp.error };

        return {
            data: resp.data.map(toFrontPageFormat),
            meta: resp.meta,
        };
    },
    linkSearch: async (params: PageGetParams): Promise<Page[] | Error> => {
        const resp = await cmsPageApi.linkSearch(formatParams(params));

        if ('error' in resp) return { error: resp.error };

        return resp.data
            .map((res) => ({
                ...res,
                draftId: null,
                published: true,
                data: {
                    seo: {
                        title: '',
                        description: '',
                        keywords: [],
                        image: null,
                    },
                    background: '#fff',
                    modules: [],
                },
                createdAt: '',
                updatedAt: '',
            }))
            .map(toFrontPageFormat);
    },
    post: async (page: Page): Promise<Page | Error> => {
        const data = toSpotonPageFormat({
            ...page,
            _id: '', // don't use auto generated ID when creating new pages
        });
        const resp = await cmsPageApi.create(data);

        if ('error' in resp) return { error: resp.error };

        return toFrontPageFormat(resp);
    },
    patch: async (page: Page): Promise<Page | Error> => {
        const resp = await cmsPageApi.update(
            page._id,
            toSpotonPageFormat(page)
        );

        if ('error' in resp) return { error: resp.error };

        return toFrontPageFormat(resp);
    },
    delete: async (page: Page): Promise<{} | Error> => {
        const resp = await cmsPageApi.delete(page._id);

        if ('error' in resp) return { error: resp.error };

        return resp;
    },
    renderModule: async (
        module: ModuleType
    ): Promise<SimpleModuleType | Error> => {
        const resp = await api.post(
            `page/render-module`,
            toSpotonModuleFormat(module)
        );

        if ('error' in resp) return { error: resp.error };

        return toFrontModuleFormat(resp);
    },
};

export default frontPageApi;
