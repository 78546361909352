import { useOrderState } from '/store/orderStore';
import { useHydration } from '/store/store.helpers';
import { JobTemplate } from '/types/jobTemplateTypes';

export interface PostCodeState {
    postCodeStateHydrated: boolean;
    resetPostCodeState: () => void;
    postCode: '';
    setPostCode: (val: string) => void;
    postCodePlace: string;
    setPostCodePlace: (val: string) => void;
    postCodeValid: boolean;
    setPostCodeValid: (val: boolean) => void;
    postCodeSupportedJobTypes: JobTemplate['typeKey'][];
    setPostCodeSupportedJobTypes: (types: JobTemplate['typeKey'][]) => void;
    postCodeNotificationRequest: boolean;
    setPostCodeNotificationRequest: (val: boolean) => void;
    cartHasJobsUnsupportedInPostCodeLocation: boolean;
}

export default function usePostCodeState(stateOverride?: PostCodeState) {
    const postCodeStateHydrated = useHydration();

    const cart = useOrderState((state) => state.cart);

    const postCode = useOrderState((state) => state.postCode);
    const setPostCode = useOrderState((state) => state.setOrderPostCode);

    const postCodePlace = useOrderState((state) => state.postCodePlace);
    const setPostCodePlace = useOrderState((state) => state.setPostCodePlace);

    const postCodeValid = useOrderState((state) => state.postCodeValid);
    const setPostCodeValid = useOrderState(
        (state) => state.setOrderPostCodeValidity
    );

    const postCodeSupportedJobTypes = useOrderState(
        (state) => state.postCodeSupportedJobTypes
    );
    const setPostCodeSupportedJobTypes = useOrderState(
        (state) => state.setOrderPostCodeSupportedJobTypes
    );

    const postCodeNotificationRequest = useOrderState(
        (state) => state.postCodeNotificationRequested
    );
    const setPostCodeNotificationRequest = useOrderState(
        (state) => state.setOrderPostCodeNotificationRequest
    );

    const cartHasJobsUnsupportedInPostCodeLocation =
        cart &&
        !!cart.lineItems.length &&
        cart.lineItems.some(
            (item) =>
                item.type === 'JOB' &&
                item.data.typeKey &&
                !postCodeSupportedJobTypes.includes(item.data.typeKey)
        );

    const resetPostCodeState = async () => {
        setPostCode('');
        setPostCodePlace('');
        setPostCodeValid(false);
        setPostCodeNotificationRequest(false);
        setPostCodeSupportedJobTypes([]);
    };

    return (
        stateOverride || {
            postCodeStateHydrated,
            resetPostCodeState,
            postCode,
            setPostCode,
            postCodePlace,
            setPostCodePlace,
            postCodeValid,
            setPostCodeValid,
            postCodeSupportedJobTypes,
            setPostCodeSupportedJobTypes,
            postCodeNotificationRequest,
            setPostCodeNotificationRequest,
            cartHasJobsUnsupportedInPostCodeLocation,
        }
    );
}
