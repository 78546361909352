export default function stripHtmlTags(input: string): string {
    if (!input) return input;

    // non-backtracking approach
    let result = '';
    let inTag = false;

    for (const char of input) {
        if (char === '<') {
            inTag = true;
            continue;
        }

        if (char === '>') {
            inTag = false;
            continue;
        }

        if (!inTag) {
            result += char;
        }
    }

    return result;
}
