export default function formatBigNumber(
    value: number | string,
    options: {
        decimals: 1 | 2 | 3;
        thousandSuffix: string;
        millionSuffix: string;
    } = {
        decimals: 2,
        thousandSuffix: 'k.',
        millionSuffix: 'mill.',
    }
): string {
    const v = Number(value);

    if (isNaN(v) || value === '') return '';
    if (v === 0) return '0';
    if (v < 1000) return v.toString();

    const x = v < 1000000 ? 1000 : 1000000;
    const y = {
        1: 10,
        2: 100,
        3: 1000,
    }[options.decimals];
    const suffix = v < 1000000 ? options.thousandSuffix : options.millionSuffix;

    return (Math.round((v / x) * y) / y + ` ${suffix}`).replace('.', ',');
}
